import React from "react";
import { PageProps } from "gatsby";
import Layout from "../layouts";

const Error404Page: React.FC<PageProps> = () => (
  <main className="full-centered">
    <h1>You are here!</h1>
    <h2>But nothing found for you #404</h2>
  </main>
);

export default Error404Page;
